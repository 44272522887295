import { Injectable } from '@angular/core';
import { getItem, removeItem, setItem, StorageItem, getUser } from 'app/core/utils';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TargetGroup } from 'app/shared/models/target-group';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { SERVER_API_URL, SERVER_API_URL_V2 } from 'app/app.constants';
import { catchError, map, tap } from 'rxjs/operators';
import { ITargetGroupRequest, TargetGroupRequest } from 'app/shared/models/target-group-request';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  public targetGroups: Array<TargetGroup> = [];
  public motivators: any[] | null = [];
  public newNotifications$ = new BehaviorSubject<any>([]);
  get newNotifications(): any {
    return this.newNotifications$.getValue();
  }


  public options: any = {
    ageRanges: {},
    educationLevels: {},
    genders: {},
    incomes: {},
    tgReportPeriods: {}
  };

  constructor(private http: HttpClient) {}

  query(): Array<TargetGroup> {
    return this.targetGroups;
  }

  listTargetGroups(): Observable<HttpResponse<any>> {
    return this.http
    .get<TargetGroup[]>(SERVER_API_URL + 'target-groups', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => this.targetGroups = response.body || []),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  create(tg: ITargetGroupRequest): Observable<HttpResponse<any>> {
    return this.http.post<ITargetGroupRequest>(SERVER_API_URL + 'target-groups', tg, { observe: 'response'});
  }

  update(tg: ITargetGroupRequest, id: string): Observable<HttpResponse<TargetGroupRequest>> {
    return this.http.put<ITargetGroupRequest>(SERVER_API_URL + 'target-groups/' + id, tg, { observe: 'response'});
  }


  deleteTG(id: string): Observable<HttpResponse<TargetGroupRequest>> {
    return this.http.delete<ITargetGroupRequest>(SERVER_API_URL + 'target-groups/' + id, { observe: 'response'});
  }

  createBrandDetails(brandDetails: any, tgId: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${SERVER_API_URL}brand-details`, brandDetails, { observe: 'response'});
  }

  updateBrandDetails(brandDetails: any, tgId: string): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${SERVER_API_URL}brand-details`, brandDetails, { observe: 'response'});
  }

  getOptions(param: string): Observable<any> {
    switch(param) {
      case "age-ranges":
        if (Object.keys(this.options.ageRanges).length) {
          return of(this.options.ageRanges);
        }
        break;
      case "education-levels":
        if (Object.keys(this.options.educationLevels).length) {
          return of(this.options.educationLevels);
        }
        break;
      case "genders":
        if (Object.keys(this.options.genders).length) {
          return of(this.options.genders);
        }
        break;
      case "incomes":
        if (Object.keys(this.options.incomes).length) {
          return of(this.options.incomes);
        }
        break;
      case "tg-report-periods":
        if (Object.keys(this.options.tgReportPeriods).length) {
          return of(this.options.tgReportPeriods);
        }
    }
    return this.http
    .get<any>(SERVER_API_URL + 'target-groups/options/' + param, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {
        if (response.body && response.body.values) {
          switch(param) {
            case "age-ranges":
              this.options.ageRanges = response.body.values;
              break;
            case "education-levels":
              this.options.educationLevels = response.body.values;
              break;
            case "genders":
              this.options.genders = response.body.values;
              break;
            case "incomes":
              this.options.incomes = response.body.values;
              break;
            case "tg-report-periods":
              this.options.tgReportPeriods = response.body.values;
              break;
          }
        }
      }),
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
      catchError(this.handleError)
    );
  }

  getTgReportTypes(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'target-groups/options/tg-report-types', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getMotivators(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'target-groups/options/motivators', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => this.motivators = response.body),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getBrandDetails(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'brand-details', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getMagicElements(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL_V2 + 'target-groups/' + tgId + '/magic-elements', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  generateMagicElements(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .post<any>(SERVER_API_URL_V2 + 'target-groups/' + tgId + '/magic-elements', {}, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getMagicElementsById(id: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL_V2 + 'magic-elements/' + id, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  // todo ne koristi se
  getAiColorsById(id: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'magic-elements/' + id + '/ai-color-sensor', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateMagicElements(reportId: string, data: any): Observable<HttpResponse<any>> {
    return this.http
    .patch<any>(`https://api.rezonate.me/api/v2/magic-elements/${reportId}`, data, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  chooseMagicElements(reportId: string, data: any, parameter: string): Observable<HttpResponse<any>> {
    return this.http
    .patch<any>(SERVER_API_URL_V2 + `magic-elements/${reportId}/${parameter}`, data, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  resetChosenWords(reportId: string): Observable<HttpResponse<any>> {
    return this.http
    .put<any>(SERVER_API_URL_V2 + `magic-elements/${reportId}/words/reset`, {}, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getBrandLifestyleKeywords(data: any): Observable<HttpResponse<any>> {
    return this.http
    .post<any>(`${SERVER_API_URL}keywords`, data, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateBrandLifestyleKeywords(targetGroupId: string, data: any): Observable<HttpResponse<any>> {
    return this.http
    .put<any>(`${SERVER_API_URL}target-groups/${targetGroupId}/brand-lifestyle-keywords`, data, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getImpactWords(reportId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}magic-elements/${reportId}/impact-words`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getMotivatorTopImpactWords(reportId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}magic-elements/${reportId}/top-motivators-impact-words`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getMotivatorImpactWords(reportId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}magic-elements/${reportId}/motivator-impact-words`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getMotivatorList(reportId: string, motivator: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}magic-elements/${reportId}/motivator-list/?&motivator=${motivator}`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  // todo ne koristi se
  getColorSensor(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'tg/' + tgId + '/ai-color-sensor', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getEmotionalIntensity(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'emotional-intensity/' + tgId + '/emotional-intensity', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getReceptivityForecast(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'receptivity-forecast?tgId=' + tgId, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getContentAmplifier(caId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'post-ideas?creativeBoardId=' + caId, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getLastCreativeBoard(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'target-groups/' + tgId + '/creative-board', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  createPostIdea(postIdea: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${SERVER_API_URL}post-ideas`, postIdea, { observe: 'response'});
  }

  updateContentAmplifier(reportId: string, body: any): Observable<HttpResponse<any>> {
    return this.http
    .put<any>(`${SERVER_API_URL}post-ideas/${reportId}`, body, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateCreativeBoard(reportId: string, body: any): Observable<HttpResponse<any>> {
    return this.http
    .patch<any>(`${SERVER_API_URL}creative-boards/${reportId}`, body, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  deleteContentAmplifier(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(SERVER_API_URL + 'post-ideas/' + id, { observe: 'response'});
  }


  // don't use
  getContentAmplifierReport(contentAmplificationReportId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'post-ideas/' + contentAmplificationReportId, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getPageContent(title: string = 'Home'): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'page-content?title=' + title, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getUserInfo(): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'user-info', { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getNotifications(userId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>('https://notifications.rezonate.me/api/v1/notifications ', {
      headers: {
      'X-ns-user-id': userId
    }, observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        this.newNotifications$.next(response.body.newNotifications);
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getCreativeBoardList(includeDrafts: boolean, targetGroupId?: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}creative-boards?includeDrafts=${includeDrafts}&targetGroupId=${targetGroupId}`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getCreativeBoard(id: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(SERVER_API_URL + 'creative-boards/' + id, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  deleteCreativeBoard(id: string): Observable<HttpResponse<any>> {
    return this.http
    .delete<any>(SERVER_API_URL + 'creative-boards/' + id, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getContentAmplificationInputs(tgId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}content-amplification-inputs?&tgId=${tgId}`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateContentAmplificationInputs(body: any): Observable<HttpResponse<any>> {
    return this.http
    .put<any>(`${SERVER_API_URL}content-amplification-inputs`, body, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  createLicenseRequest(license: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(SERVER_API_URL + 'license-upgrade', license, { observe: 'response'});
  }

  // schedule page
  getPostsByTG(targetGroupId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}target-groups/${targetGroupId}/posts`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getPostsByGroup(groupId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}post-groups/${groupId}/posts`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response.body
      }),
      catchError(this.handleError)
    );
  }

  getPostsCalendar(targetGroupId: string, startMonth?: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}posts/calendar?&targetGroupId=${targetGroupId}&startMonth=${startMonth}`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getPostAdjustmentUsageInfo(targetGroupId: string): Observable<HttpResponse<any>> {
    return this.http
    .get<any>(`${SERVER_API_URL}post-adjustments?&targetGroupId=${targetGroupId}`, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  adjustPostContent(data: any): Observable<HttpResponse<any>> {
    return this.http
    .post<any>(`${SERVER_API_URL}post-adjustments`,
    data, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getPostsCalendarByDate(targetGroupId: string, dates?: string[]): Observable<HttpResponse<any>> {
    return this.http
    .post<any>(`${SERVER_API_URL}posts/calendar`,
    {
      targetGroupId,
      dates
    }, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getPostGroups(creativeBoardId: string, targetGroupId: string, page: number = 0, query?: string): Observable<HttpResponse<any>> {
    let url = `${SERVER_API_URL}post-groups/?`;
    url = !!query ? url + `&query=${query}` : url;
    url = !!page ? url + `&page=${page}` : url;
    url = !!targetGroupId ? url + `&targetGroupId=${targetGroupId}` : url + `&creativeBoardId=${creativeBoardId}`
    return this.http
    .get<any>(url, { observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  editPostGroups(postGroup: {
    name: "string",
    publishTime: "string",
    scheduleType: "string",
    startDate: "string"
  }, postGroupId: string): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${SERVER_API_URL}post-groups/${postGroupId}`, postGroup,
    { observe: 'response',
      // headers: {
      //   // 'Content-Type': "multipart/form-data"
      // }
    });
  }

  updatePosts(postGroupId: string, body: any): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${SERVER_API_URL}post-groups/${postGroupId}/posts`, body,
      { observe: 'response',
        // headers: {
        //   // 'Content-Type': "multipart/form-data"
        // }
      });
  }

  generateImage(body: any): Observable<HttpResponse<any>> {

    return this.http
    .post<any>(`https://dali.rezonate.me/api/v1/image-generator`, body, {
      headers: {
      'x-dali-session': '8382d896-2ad8-46d2-b20b-25ca8c4be056'
    }, observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {}),
      map((response: HttpResponse<any>) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  createPost(post: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${SERVER_API_URL}posts`, post,
    { observe: 'response',
      // headers: {
      //   // 'Content-Type': "multipart/form-data"
      // }
    });
  }

  editPost(post: any, postId: string): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${SERVER_API_URL}posts/${postId}`, post,
    { observe: 'response',
      // headers: {
      //   // 'Content-Type': "multipart/form-data"
      // }
    });
  }

  deletePost(postId: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${SERVER_API_URL}posts/${postId}`, { observe: 'response' });
  }


  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);

    if (!!error.error && !!error.error.message) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }
}
